/*const MenuSidebar = [
    {
        name: 'Dashboard',
        icon: 'icon-home',
        translate: 'sidebar:dashboard',
        path: UtilUrl.generate(routes.dashboard)
    },
    {
        name: 'Agenda',
        icon: 'icon-calendar',
        translate: 'sidebar:agenda:main',
        submenu: [{
            name: 'Mijn agenda',
            translate: 'sidebar:agenda:myagenda',
            path: UtilUrl.generate(routes.myagenda)
        },
        {
            name: 'Huisbezoeken',
            translate: 'sidebar:agenda:homevisits',
            path: UtilUrl.generate(routes.homevisits.overview)
        },
        {
            name: 'Groepsoverzicht',
            translate: 'sidebar:agenda:groupoverview',
            path: UtilUrl.generate(routes.group)

        },
        {
            name: 'Zoek vrij tijdstip',
            translate: 'sidebar:agenda:searchfreeslot',
            path: UtilUrl.generate(routes.agenda.searchfreeslot)
        },
        {
            name: 'Dagafspraken',
            translate: 'sidebar:agenda:dayappointments',
            path: UtilUrl.generate(routes.dayappointments.overview)
        },
        {
            name: 'Historiek',
            translate: 'sidebar:agenda:history',
            path: UtilUrl.generate(routes.agenda.history)
        },
        {
            name: 'Conflicten',
            translate: 'sidebar:agenda:conflicts',
            path: UtilUrl.generate(routes.agenda.conflicts),
            label: 'appointmentConflicts'
        }]
    },
    {
        name: 'Patiënten',
        icon: 'icon-people',
        translate: 'sidebar:patients:main',
        path: UtilUrl.generate(routes.patients.overview)
    }
];*/

const MenuSidebar = [];

export default MenuSidebar;
